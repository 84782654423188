import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service";
export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },

  name: "shop-tags",
  data() {
    return {
      requiredRule: [v => !!v || "Field is required"],
      isSaving: false,
      shopId: null,
      valid: false,
      tags: []
    };
  },
  created() {
    this.shopId = this.$route.params.shopId;
    this.$log.debug("shopid: ", this.shopId);
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Shop tags" }]);

    if (this.shopId != null) {
      ApiService.get(`api/shops`, `${this.shopId}/tags`)
        .then(response => {
          this.$log.debug("tags response: ", response.data);
          this.tags = response.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    }
  },
  methods: {
    previousState() {
      this.$router.go(-1);
    },
    validateAndSave() {
      if (this.$refs.form.validate()) {
        this.saveShopTags(this.tags, this.shopId);
      }
    },
    saveShopTags(tags, shopId) {
      this.loading = true;
      this.isSaving = true;
      this.$log.debug("Tags for saving: " + tags);
      this.$log.debug("shopId: " + shopId);
      let shopTags = {};
      shopTags["shopId"] = shopId;
      shopTags["tags"] = tags;
      ApiService.post(`api/shops/tags`, shopTags)
          .then(response => {
            this.$log.debug("Shop tags created: " + response);
            this.$emit("shopTagsCreated", "shopTagsCreated");
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.isSaving = false;
            this.previousState();
          });
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("input", value);
        }
      }
    }
  }
};
